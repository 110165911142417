<template>
  <Modal
    title="会员列表"
    v-model="isShow"
    width="1200"
    class="select-member"
    class-name="i-modal"
  >
    <Form :label-width="80" :model="params" ref="searchForm">
      <Row :gutter="16">
        <i-col span="4">
          <FormItem label="会员编号:" prop="MemberCode">
            <Input v-model="params.MemberCode" />
          </FormItem>
        </i-col>
        <i-col span="4">
          <FormItem label="会员名称:" prop="MemberName">
            <Input v-model="params.MemberName" />
          </FormItem>
        </i-col>
        <i-col span="4">
          <FormItem label="手机号:" prop="Mobile">
            <Input v-model="params.Mobile" />
          </FormItem>
        </i-col>
        <i-col span="4">
          <FormItem label="身份证号:" prop="IdentityCard">
            <Input v-model="params.IdentityCard" />
          </FormItem>
        </i-col>

        <i-col span="4" class="mt5">
          <Button
            type="primary"
            size="small"
            ghost
            @click="loadingMemberList"
            icon="ios-search"
          >
            搜索
          </Button>
          <Button class="ml2" @click="handleReset" size="small"> 重置 </Button>
        </i-col>
      </Row>
    </Form>

    <Row :gutter="16">
      <i-col span="14" class="productTableConMember">
        <Table
          ref="table"
          class="main-table"
          border
          size="small"
          stripe
          :columns="columns"
          :data="Member.DataList"
          :loading="tableLoading"
          :height="tableHeight"
          :row-class-name="rowClassName"
          @on-sort-change="sortChange"
          @on-selection-change="memberSelectionClick"
        >
          <template slot="Action" slot-scope="{ row }">
            <Button
              class="ml2"
              size="small"
              type="primary"
              @click="memberHandleSel(row)"
            >
              添加
            </Button>
          </template>
        </Table>
        <Row class="mt10">
          <i-col span="20">
            <Page
              :page-size-opts="[40, 60, 80, 100]"
              :page-size="40"
              :total="Member.TotalCount"
              :current="params.Page"
              @on-page-size-change="memberChangePageSize"
              @on-change="memberChangePage"
              size="small"
              placement="top"
              show-elevator
              show-sizer
              show-total
            />
          </i-col>
          <i-col span="4" style="text-align: right">
            <Button
              type="success"
              icon="trash-a"
              v-if="type === 'many'"
              @click="memberAddSelection"
            >
              <span>批量添加</span>
            </Button>
          </i-col>
        </Row>
      </i-col>
      <i-col span="10">
        <Table
          ref="table"
          border
          size="small"
          stripe
          :columns="selectColumns"
          :data="CheckMemberList"
          :height="tableHeight"
          @on-sort-change="sortChange"
          @on-selection-change="memberSelectionClick1"
        >
          <template slot="Action" slot-scope="{ row }">
            <Button
              class="ml2"
              size="small"
              type="error"
              @click="memberClearItem([row])"
            >
              移除
            </Button>
          </template>
        </Table>
        <Row style="text-align: right">
          <Button
            class="mt10"
            size="small"
            type="error"
            @click="memberClearItem(selectCheckMemberList)"
          >
            批量移除
          </Button>
        </Row>
      </i-col>
    </Row>

    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="memberYes" :loading="SaveLoading">
        确定
      </Button>
    </div>
  </Modal>
</template>
<script>
// import { mapActions } from 'vuex';
import api from "@/api";
export default {
  props: {
    type: {
      type: String,
      default: "many",
    },
  },
  data() {
    return {
      isShow: false,
      params: {
        MemberCode: "",
        MemberName: "",
        Mobile: "",
        IdentityCard: "",
        DepartmentID: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        { type: "selection", width: 58, align: "center" },
        {
          title: "会员编号",
          key: "MemberCode",
          align: "center",
          minWidth: 90,
        },
        {
          title: "会员名称",
          key: "MemberName",
          align: "center",
          minWidth: 150,
        },
        {
          title: "会员级别",
          key: "MemberLevelID",
          align: "center",
          minWidth: 100,
        },
        { title: "手机号", key: "Mobile", align: "center", minWidth: 100 },
        {
          title: "状态",
          key: "MemberStatusCN",
          align: "center",
          minWidth: 100,
        },
        {
          title: "操作",
          key: "action",
          slot: "Action",
          align: "center",
          fixed: "right",
          width: 60,
        },
      ],
      selectColumns: [
        { type: "selection", width: 58, align: "center" },
        {
          title: "会员编号",
          key: "MemberCode",
          align: "center",
          minWidth: 90,
        },
        {
          title: "会员名称",
          key: "MemberName",
          align: "center",
          minWidth: 150,
        },
        { title: "手机号", key: "Mobile", align: "center", minWidth: 100 },
        {
          title: "操作",
          key: "action",
          slot: "Action",
          align: "center",
          fixed: "right",
          width: 60,
        },
      ],
      Member: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      tableLoading: false,
      tableHeight: 0,
      selection2: [],
      CheckMemberList: [],
      WaitDelete1: false,
      SaveLoading: false,
      loadNum: 0,
      WaitDelete2: false,
      selectCheckMemberList: [],
    };
  },
  watch: {
    isShow(val) {
      if (val) {
        this.loadingMemberList();
      } else {
        this.CheckMemberList = [];
      }
    },
  },
  created() {},
  methods: {
    rowClassName(row) {
      if (
        this.CheckMemberList.find((item) => item.MemberCode === row.MemberCode)
      ) {
        return "tdSelected";
      } else {
        return "";
      }
    },
    // 加载会员列表
    async loadingMemberList() {
      const _this = this;
      _this.tableLoading = true;
      try {
        const res = await api.GetMemberList(this.params);
        this.Member = res.Data;
        this.tableLoading = false;
        this.$nextTick(() => {
          !this.loadNum &&
            (this.tableHeight = this.$getTableHeight(
              "productTableConMember",
              10
            ));
          this.loadNum++;
        });
      } catch (err) {
        this.tableLoading = false;
      }
    },
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadingMemberList();
    },
    memberSelectionClick(selection) {
      const _this = this;
      if (selection.length > 0) _this.WaitDelete2 = true;
      else _this.WaitDelete2 = false;
      _this.selection2 = [];
      selection.map((item) => {
        _this.selection2.push(item);
      });
    },
    memberSelectionClick1(selection) {
      this.selectCheckMemberList = selection;
    },
    memberHandleSel(row) {
      if (this.type !== "many" && this.CheckMemberList.length) {
        return this.$Message.error("当前只支持单选");
      }
      if (
        this.CheckMemberList.indexOf(
          this.CheckMemberList.find((item) => {
            return item.MemberCode === row.MemberCode;
          })
        ) === -1
      ) {
        this.CheckMemberList.push(row);
      }
    },
    // 用户选择清除
    memberClearItem(arr) {
      arr.some((res) => {
        this.CheckMemberList.splice(
          this.CheckMemberList.indexOf(
            this.CheckMemberList.find((item) => {
              return item.MemberCode === res.MemberCode;
            })
          ),
          1
        );
      });
    },
    memberAddSelection() {
      const _this = this;
      if (_this.WaitDelete2) {
        _this.selection2.map((item) => {
          if (
            _this.CheckMemberList.indexOf(
              _this.CheckMemberList.find((cell) => {
                return cell.MemberCode === item.MemberCode;
              })
            ) === -1
          ) {
            _this.CheckMemberList.push(item);
          }
        });
      } else {
        _this.$Message.error("请先选择需要添加的行！");
      }
    },
    memberChangePageSize(pagesize) {
      this.params.PageSize = pagesize;
      this.loadingMemberList();
    },
    memberChangePage(page) {
      this.params.Page = page;
      this.loadingMemberList();
    },
    memberYes() {
      this.$emit("selectOk", this.CheckMemberList);
      this.isShow = false;
    },
    handleReset() {
      this.$refs.searchForm.resetFields();
      this.loadingMemberList();
    },
  },
};
</script>
<style lang="less">
.select-member {
  .ivu-table-row.tdSelected td {
    background-color: #ebf7ff !important;
    color: #515a6e;
  }
  .ivu-table-body {
    padding-bottom: 0 !important;
  }
}
</style>
