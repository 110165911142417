<template>
  <Modal
    v-model="isShow"
    title="奖金明细"
    class-name="i-modal"
    width="700px"
    :z-index="1000"
  >
    <Form
      :model="obj"
      class="modelViewFrom"
      ref="form"
      label-position="left"
      :rules="validateRules"
      :label-width="80"
    >
      <Row>
        <FormItem prop="AddMemberCode" label="会员编号">
          <Input
            ref="AddMemberCode"
            style="width: 70%"
            type="text"
            :rows="4"
            v-model="AddMemberCode"
            disabled
          ></Input>
          <Button
            style="margin-left: 10px"
            type="primary"
            @click="CheckMember()"
            size="small"
            :disabled="!isAdd"
            >选择</Button
          >
        </FormItem>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="AddType" label="收支类型">
            <Select
              clearable
              v-model="AddType"
              @on-change="SeleceChange1"
              placeholder="收支类型"
            >
              <Option
                v-for="item in BonusAddTypeEnum"
                :value="item.ID"
                :key="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="AddBonusType" label="奖金类型">
            <Select
              clearable
              filterable
              v-model="AddBonusType"
              placeholder="奖金类型"
            >
              <Option
                v-for="item in BonusTypeList"
                :value="item.ID"
                :key="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem prop="AddAmount" label="金额">
            <Input type="number" v-model="AddAmount"></Input>
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem prop="AddBusinessCode" label="业务编号">
            <Input type="text" v-model="AddBusinessCode"></Input>
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="24">
          <FormItem prop="AddRemark" label="备注">
            <Input type="text" v-model="AddRemark"></Input>
          </FormItem>
        </Col>
      </Row>
    </Form>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="handleSave" :loading="SaveLoading">
        {{ !SaveLoading ? "保存" : "保存中..." }}
      </Button>
    </div>

    <!--选择会员-->
    <select-member
      ref="selectMember"
      type="single"
      @selectOk="MemberHandleSel"
    />
  </Modal>
</template>

<script>
import api from "@/api";
import selectMember from "@/components/select-member/index.vue";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "bonus-detail-add",
  data() {
    return {
      CheckView: false,
      CheckMemberList1: [],
      WaitDelete1: false,
      isShow: false,
      isEdit: false,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      AddMemberCode: "",
      AddType: 2,
      AddBonusType: "",
      AddAmount: 0,
      AddBusinessCode: "",
      AddRemark: "",
      BonusTypeList: [],
      obj: {},
      validateRules: {
        // AddBonusType: [
        //   { required: true, message: '奖金类型不能为空' }
        // ],
        // AddMemberCode: [
        //   { required: true, message: '会员编号不能为空' }
        // ]
      },
    };
  },
  methods: {
    SeleceChange1(value) {
      const _this = this;
      if (value === 20) {
        _this.BonusTypeList = _this.SpendTypeEnum;
      } else {
        _this.BonusTypeList = _this.InComeTypeEnum;
      }
    },
    async handleSave() {
      const _this = this;
      try {
        if (_this.AddBonusType === "" || _this.AddBonusType === 0) {
          _this.$Message.warning("请选择类型");
          return;
        }
        if (_this.AddMemberCode === "") {
          _this.$Message.warning("请选择会员");
          return;
        }
        _this.$refs.form.validate(async (valid) => {
          if (valid) {
            _this.SaveLoading = true;
            const response = await api.SaveBonusDetail({
              memberCode: _this.AddMemberCode,
              type: _this.AddType,
              bonusType: _this.AddBonusType,
              amount: _this.AddAmount,
              businessCode: _this.AddBusinessCode,
              remark: _this.AddRemark,
            });
            if (response.Status === 100) {
              _this.$Message.success("操作成功！");
              this.$emit("on-save", response.Data);
              _this.SaveLoading = false;
              _this.isShow = false;
              _this.$emit("on-save", this.obj);
            } else {
              _this.SaveLoading = false;
              _this.isShow = false;
              _this.$Message.error(response.Msg);
            }
          } else {
            _this.SaveLoading = false;
            _this.$Message.error("填写信息有误！");
          }
        });
      } catch (error) {
        _this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
      _this.SaveLoading = false;
    },
    CheckMember() {
      this.$refs.selectMember.isShow = true;
    },
    MemberHandleSel(row) {
      this.AddMemberCode = row.length ? row[0].MemberCode : "";
    },
  },
  watch: {
    isShow: function (val) {
      if (val && this.obj.Logo) {
        let files = [
          {
            _key: this.obj.Logo,
            _file: this.$setImagePrefix(this.obj.Logo),
            success: true,
          },
        ];
        this.files = this.$refs.UploadLogistic.uploadFiles = files;
      } else {
        this.files = [];
      }
    },
  },
  computed: {
    BonusAddTypeEnum() {
      return bizEnum.BonusAddTypeEnum;
    },
    InComeTypeEnum() {
      return bizEnum.InComeTypeEnum;
    },
    SpendTypeEnum() {
      return bizEnum.SpendTypeEnum;
    },
  },
  components: { selectMember },
};
</script>
