<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input
                placeholder="请输入会员编号"
                v-model="params.MemberCode"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="业务编号:" prop="BusinessCode">
              <Input
                placeholder="请输入业务编号"
                v-model="params.BusinessCode"
                clearable
              />
            </FormItem>
          </Col>
          <!-- <Col span="6">
          <FormItem label="业务ID:"
                    prop="SourceID">
            <Input placeholder="请输入抽奖/红包ID"
                   v-model="params.SourceID"
                   clearable />
          </FormItem>
          </Col> -->
          <Col span="6">
            <FormItem label="奖金类型:" prop="Type">
              <Select clearable v-model="params.Type" placeholder="奖金类型">
                <Option
                  v-for="item in BonusTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                  >{{ item.CN }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="日期范围:" prop="TempDate">
              <Date-picker
                style="width: 100%"
                clearable
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                placement="bottom-end"
                placeholder="日期范围"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="dateUpdate"
              ></Date-picker>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Bonus_Edit--'])"
            @click="handleOpenAdd"
            >新增</Button
          >
          <Button
            type="success"
            class="ml2"
            icon="md-cloud-download"
            @click="ExportInfo"
            >导出</Button
          >
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
            >收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="BusinessCode" slot-scope="{ row }">
        <Span
          style="color: green; cursor: pointer"
          @click="openOrderDrawer(row, true)"
          >{{ row.BusinessCode }}</Span
        >
      </template>
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
          >{{ row.MemberCode }}</span
        >
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="primary"
          v-if="row.Type === 101 || row.Type === 102 || row.Type === 103"
          @click="achiveDrawer(row)"
          >查看明细</Button
        >
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!--    订单明细信息展示抽屉-->
    <OrderDetailDrawer ref="orderDetailDrawer" />
    <!--    用户信息编辑抽屉-->
    <MemberDrawer ref="memberDrawer" />
    <!--    添加明细抽屉-->
    <DetailAddDrawer ref="detailAddDrawer" @on-save="onUpdated" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import moment from "moment";
import Layout from "@/components/layout/index.vue";
import MemberDrawer from "../member/components/member-drawer.vue";
import OrderDetailDrawer from "../order/components/detail-drawer.vue";
import DetailAddDrawer from "./components/bonus-detail-add.vue";
export default {
  name: "BonusDetailList",
  data() {
    return {
      TempDate: [],
      tableLoading: false,
      tableHeight: 0,
      SuperBonus: 0,
      RowID: 0,
      BonusLoading: false,
      params: {
        SourceName: "",
        BusinessCode: "",
        MemberCode: "",
        SourceID: "",
        DateBegin: "",
        DateEnd: "",
        Type: "",
        Page: 1,
        PageSize: 10,
        SortName: "ID",
        SortOrder: "Desc",
      },
      columns: [
        { title: "ID", key: "ID", minWidth: 70 },
        {
          title: "业务编号",
          key: "BusinessCode",
          slot: "BusinessCode",
          minWidth: 175,
        },
        {
          title: "会员编号",
          sortable: "custom",
          key: "MemberCode",
          slot: "MemberView",
          minWidth: 90,
        },
        { title: "商品名", key: "ProductName", minWidth: 120 },
        { title: "商品数量", key: "ProductCount", minWidth: 80 },
        { title: "金额", sortable: "custom", key: "Amount", minWidth: 80 },
        { title: "类型", key: "TypeCN", minWidth: 120 },
        { title: "状态", key: "StatusCN", minWidth: 120 },
        { title: "日期", sortable: "custom", key: "CreateDate", minWidth: 135 },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {
    memberStateEnum() {
      return bizEnum.MemberStatusEnum;
    },
    BonusTypeEnum() {
      return bizEnum.BonusTypeEnum;
    },
  },
  created() {
    // this.columns.push({
    //   title: "操作",
    //   key: "action",
    //   slot: "Action",
    //   align: "center",
    //   fixed: "right",
    //   minWidth: 55,
    //   maxWidth: this.$route.meta.tableActionWidth,
    // });
    this.loadMemberBonusList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberBonusList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadMemberBonusList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        self.loadMemberBonusList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadMemberBonusList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadMemberBonusList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    async loadMemberBonusList() {
      this.tableLoading = true;
      try {
        const res = await api.GetExpectBonusDetailList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    async openOrderDrawer(row) {
      try {
        let res = await api.GetOrderByCode({
          code: row.BusinessCode,
        });
        if (res.Data) {
          this.$refs.orderDetailDrawer.obj = res.Data;
          this.$refs.orderDetailDrawer.isShow = true;
          this.$refs.orderDetailDrawer.isEdit = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    ExportInfo() {
      let filename = `奖金明细 ${moment(this.params.begindate).format(
        "YYYYMMDD"
      )}-${moment(this.params.enddate).format("YYYYMMDD")}.xls`;
      this.params.exportName = filename;
      api.ExportBonusDetail(this.params);
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadMemberBonusList();
    },
    handleReset() {
      this.TempDate = [];
      this.params.DateBegin = "";
      this.params.DateEnd = "";
      this.$refs.SearchForm.resetFields();
      this.loadMemberBonusList();
    },
    handleOpenAdd() {
      this.$refs.detailAddDrawer.AddType = 2;
      this.$refs.detailAddDrawer.AddMemberCode = "";
      this.$refs.detailAddDrawer.AddBonusType = 0;
      this.$refs.detailAddDrawer.AddBusinessCode = "";
      this.$refs.detailAddDrawer.AddRemark = "";
      this.$refs.detailAddDrawer.AddAmount = 0;
      this.$refs.detailAddDrawer.BonusTypeList =
        this.$refs.detailAddDrawer.SpendTypeEnum;
      this.$refs.detailAddDrawer.isShow = true;
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadMemberBonusList();
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.memberDrawer.obj = res.Data;
          this.$refs.memberDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.loadMemberBonusList();
      });
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
    achiveDrawer(row) {
      const params = {
        TeamID: row.TeamMemberCode,
        MemberCode: row.MemberCode,
        BeginDate: `${this.$moment(row.CreateDate)
          .subtract(1, "month")
          .format("YYYY-MM")}-01`,
        EndDate: `${this.$moment(row.CreateDate).format("YYYY-MM")}-01`,
      };
      if (row.Type === 101) {
        params.Type = "10,40";
      }
      if (row.Type === 102) {
        params.Type = "20,50";
      }
      if (row.Type === 103) {
        params.Type = "30,60";
      }
      this.$refs.achieveDrawer.params = params;

      this.$refs.achieveDrawer.isShow = true;
    },
  },
  components: {
    OrderDetailDrawer,
    DetailAddDrawer,
    MemberDrawer,
    Layout,
  },
};
</script>
